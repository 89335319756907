import React from 'react'

const reviews = () => {
  return (
    <div className='pd-top-100'>
        <h2 className='text-center'>What Our Clients Say</h2>
    <div class="elfsight-app-2dacd74e-c30a-43f9-abb3-83c1fd613094"></div></div>
  )
}

export default reviews