import React from "react";
// import { GlobalDataContext } from "../../context/context";

//

const LiveChat = (props) => {
  // const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
<div class="elfsight-app-7dc40a17-fe9a-4923-95e6-fd1f289b76cb"></div>
      <div className="visor_Counter">
<div class="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
      </div>
    </div>
  );
};

export default LiveChat;
