import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Navbar from './global/navbar';
import PageHeader from './global/page-header';
import Reviews from './home-components/reviews';
import Footer from './global/footer';


const Review = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <PageHeader headertitle="Reviews" Subheader="Reviews" bgimg={`${rpdata?.gallery?.[8]}`} />
        <Reviews/>
        <Footer />
    </div>
}

export default Review

